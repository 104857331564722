
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import { getAuth, RecaptchaVerifier } from 'firebase/auth';

    @Component({})
    export default class RecaptchaComponent extends Vue {
        public recaptchaContainers: string[] = [];
        public recaptchaVerifier: RecaptchaVerifier | null = null;
        public recaptchaWidgetId: number | null = null;

        public renderAndVerifyRecaptcha() {
            return new Promise<string>((resolve, reject) => {
                const elementId = this.getRandomId();

                this.recaptchaContainers.push(elementId);

                this.$nextTick(() => {
                    this.recaptchaVerifier = new RecaptchaVerifier(elementId, {
                        size: 'invisible',
                    }, getAuth());

                    this.recaptchaVerifier
                        .render()
                        .then((recaptchaWidgetId: number) => {
                            this.recaptchaWidgetId = recaptchaWidgetId;
                            this.recaptchaVerifier
                                ?.verify()
                                .then((recaptchaToken: string) => resolve(recaptchaToken))
                                .catch((error) => reject(error));
                        })
                        .catch((error) => reject(error));
                });
            });
        }

        private getRandomId() {
            return String.fromCharCode(65 + Math.floor(Math.random() * 26)) + Date.now();
        }
    }
