
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {getModule} from 'vuex-module-decorators';
    import {namespace} from 'vuex-class';

    import {AccountModule, SnackbarModule} from '@/store/modules';
    import {formHelper, bannerHelper} from '@/helpers';
    import RecaptchaComponent from '@/components/RecaptchaComponent.vue';
    import PhoneInput from '@/components/forms/PhoneInput.vue';
    import {
        IAccount,
        IClient,
        IUser, 
        IUpdateClientData
    } from '@/types';

    const accountNamespace = namespace('account');

    @Component({
        components: {
            PhoneInput,
            RecaptchaComponent,
        },
    })
    export default class ViewProfile extends Vue {
        public accountFormValid: boolean = false;
        public profileFormValid: boolean = false;
        public submittingAccount: boolean = false;
        public submittingProfile: boolean = false;
        public deletingAccount: boolean = false;
        public deleteDialog: boolean = false;
        public dynaban: string = '';
        public firstName: string = '';
        public lastName: string = '';
        public cellPhone: string = '';
        public homePhone: string|undefined = '';
        public email: string = '';
        public password: string = '';;
        public passwordConfirmation: string = '';
        public openDialog: boolean = false;
        public optinInsurance: boolean|string|undefined = '';

        @accountNamespace.Getter('loggedClient')
        public loggedClient!: IClient;

        @accountNamespace.Getter('loggedAccount')
        public loggedAccount!: IAccount;

        @accountNamespace.Getter('isValidated')
        public isValidated!: boolean;

        @accountNamespace.Getter('isPendingDetails')
        public isPendingDetails!: boolean;

        @accountNamespace.Getter('isPendingValidation')
        public isPendingValidation!: boolean;

        @accountNamespace.Getter('loggedUser')
        public loggedUser!: IUser;

        public emailRules = formHelper.getEmailsRules();
        
        public passwordRules = [
            (v: string) => (!v || /^(?=.*[A-Za-z])(?=.*\d)(?=.*[\S])[A-Za-z\d\S]{12,}$/.test(v)) || 'Le mot de passe doit contenir au moins 12 caractères, au moins un chiffre et un caractère spécial minimum.'
        ];

        public passwordConfirmationRules = [
            this.validatePasswordConfirmation,
        ];

        get accountFormChanged() {
            return this.email !== this.loggedAccount.email || this.password;
        }

        get profileFormChanged() {
            if (!this.loggedClient) {
                return false;
            }

            const cellPhone = this.cellPhone === undefined ? null : this.cellPhone;
            const homePhone = this.homePhone === undefined ? null : this.homePhone;

            return cellPhone !== this.loggedClient.cell_phone ||
                homePhone !== this.loggedClient.home_phone;
        }

        public validatePasswordConfirmation(v: string) {
            return v === this.password || 'Les mots de passe doivent être identiques';
        }

        public checkPasswordConfirmation() {
            (this.$refs.passwordConfirmation as any).validate();
        }

        public submitAccountForm() {
            this.submittingAccount = true;

            getModule(AccountModule, this.$store)
                .updateAccount({
                    email: this.email as string,
                    password_raw: this.password,
                })
                .then(() => {
                    getModule(SnackbarModule, this.$store)
                        .display({
                            message: 'Votre compte a bien été mis à jour',
                            position: 'top',
                            color: 'success',
                        })
                    ;
                })
                .catch(() => {
                    getModule(SnackbarModule, this.$store)
                        .display({
                            message: 'Une erreur est survenue, veuillez réessayer ulterieurement',
                            position: 'top',
                            color: 'error',
                        })
                    ;
                })
                .finally(() => this.submittingAccount = false)
            ;
        }

        public submitProfileForm() {
            const cellPhone = this.cellPhone === undefined ? null : this.cellPhone;
            const homePhone = this.homePhone === undefined ? null : this.homePhone;

            if (cellPhone !== this.loggedClient.cell_phone) {
                (this.$refs.recaptchaVerifier as RecaptchaComponent)
                    .renderAndVerifyRecaptcha()
                    .then((recaptchaToken: string) => {
                        this.sendProfileUpdateRequest({
                            client: {
                                cell_phone: cellPhone as string,
                                home_phone: homePhone,
                                has_optin_insurance: this.optinInsurance ? 'enabled' : 'disabled'
                            },
                            recaptcha_token: recaptchaToken,
                        }, true);
                    })
                ;
            } else {
                this.sendProfileUpdateRequest({
                    client: {
                        cell_phone: cellPhone,
                        home_phone: homePhone,
                        has_optin_insurance: this.optinInsurance ? 'enabled' : 'disabled'
                    },
                });
            }
        }

        public sendProfileUpdateRequest(params: IUpdateClientData, cellPhoneUpdated: boolean = false) {
            this.submittingProfile = true;

            getModule(AccountModule, this.$store)
                .updateClient(params)
                .then(() => {
                    getModule(SnackbarModule, this.$store)
                        .display({
                            message: cellPhoneUpdated ?
                                'Votre profil a bien été mis à jour.<br>Vous allez recevoir un SMS afin de valider votre numero de téléphone' :
                                'Votre profil a bien été mis à jour'
                            ,
                            position: 'top',
                            color: 'success',
                        })
                    ;
                })
                .catch(() => {
                    getModule(SnackbarModule, this.$store)
                        .display({
                            message: 'Une erreur est survenue, veuillez réessayer ulterieurement',
                            position: 'top',
                            color: 'error',
                        })
                    ;
                })
                .finally(() => this.submittingProfile = false)
            ;
        }

        public deleteAccount() {
            this.deletingAccount = true;

            const accountModule = getModule(AccountModule, this.$store);
            const snackbarModule = getModule(SnackbarModule, this.$store);

            accountModule
                .deleteAccount()
                .then(() => {
                    this.$router
                        .push({name: 'home'})
                        .then(() => {
                            accountModule
                                .logout()
                                .then(() => {
                                    snackbarModule
                                        .display({
                                            message: 'Votre compte a bien été supprimé',
                                            position: 'top',
                                            color: 'success',
                                        })
                                    ;
                                })
                            ;
                        })
                    ;
                })
                .catch(() => {
                    snackbarModule
                        .display({
                            message: 'Une erreur est survenue, veuillez réessayer ulterieurement',
                            position: 'top',
                            color: 'error',
                        })
                    ;
                })
                .finally(() => this.deletingAccount = false)
            ;
        }

        public mounted() {
            if (this.loggedClient) {
                this.firstName = this.loggedClient.first_name;
                this.lastName = this.loggedClient.last_name;
                this.cellPhone = this.loggedClient.cell_phone;
                this.homePhone = this.loggedClient.home_phone;
                this.optinInsurance = this.loggedClient.has_optin_insurance==='enabled' ? true : false;
            }

            this.email = this.loggedAccount.email;
        }

        public created() {
            this.dynaban = bannerHelper.getDynaBan();
        }
    }
