
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {getModule} from 'vuex-module-decorators';
    import {namespace} from 'vuex-class';
    import {Route, NavigationGuardNext} from 'vue-router';

    import AppointmentCard from '@/components/AppointmentCard.vue';
    import {BookingModule} from '@/store/modules';
    import {IAppointment, IBooking, IClient} from '@/types';

    const accountNamespace = namespace('account');
    const bookingNamespace = namespace('booking');

    @Component<ViewAppointments>({
        components: {
            AppointmentCard,
        },
        beforeRouteUpdate(to: Route, from: Route, next: NavigationGuardNext) {
            if (to.name === 'appointment') {
                const matchingRef = this.$refs['appointment' + to.params.id] as Element[];

                if (matchingRef.length > 0) {
                    this.selectedElement = matchingRef[0] as HTMLElement;
                } else {
                    this.selectedElement = null;
                }
            } else {
                this.selectedElement = null;
            }

            next();
        },
    })
    export default class ViewAppointments extends Vue {
        public selectedElement: HTMLElement | null = null;

        @accountNamespace.Getter('loggedClient')
        public client!: IClient;

        @bookingNamespace.Getter('bookingsList')
        public bookings!: IBooking[];

        get mode() {
            if (this.$route.name === 'appointment') {
                return 'detail';
            }

            return 'listing';
        }

        public goTo(appointment: IAppointment) {
            if (this.$route.params.id === appointment.id.toString()) {
                return this.$router.push({name: 'appointments'});
            } else {
                return this.$router.push({name: 'appointments'});
            }
        }

        private created() {
            getModule(BookingModule, this.$store)
                .fetchBookings(this.client.id)
            ;
        }
    }
