
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {getModule} from 'vuex-module-decorators';
    import {namespace} from 'vuex-class';
    import {Prop} from 'vue-property-decorator';

    import {
        AnimalModule,
        SnackbarModule,
        BookingModule,
    } from '@/store/modules';

    import BirthDateInput from '@/components/forms/BirthDateInput.vue';

    import {
        IAnimal,
        IAnimalFormParams,
        IBreed,
        ISpecies,
        IUser,
        IClient,
    } from '@/types';

    const accountNamespace = namespace('account');
    const animalNamespace = namespace('animal');

    @Component<AnimalFormCard>({
        components: {
            BirthDateInput,
        },
    })
    export default class AnimalFormCard extends Vue {
        public animalFormValid = false;

        @Prop({
            type: Boolean,
            required: false,
            default: false,
        })
        public forceSpecies!: boolean;

        @Prop({
            type: Object,
            required: true,
        })
        public value!: IAnimal;

        @animalNamespace.Getter('speciesList')
        public speciesList!: ISpecies[];

        @animalNamespace.Getter('breedsList')
        public breedsList!: IBreed[];

        @accountNamespace.Getter('loggedClient')
        public loggedClient!: IClient;

        @accountNamespace.Getter('loggedUser')
        public loggedUser!: IUser;

        public nameRules = [
            (v: string) => !!v || 'Le nom est requis',
        ];

        public speciesRules = [
            (v: string) => !!v || 'L\'espèce est requise',
        ];

        public sexRules = [
            (v: string) => !!v || 'Le sexe est requis',
        ];

        get filteredBreedsList() {
            if (!this.value.species) {
                return [];
            }

            return this.breedsList.filter((breed: IBreed) => breed.species_id === this.value.species.id);
        }

        get cardTitle() {
            if (this.value.id) {
                return 'Modifier un animal';
            }

            return 'Ajouter un animal';
        }

        get submitButtonTitle() {
            if (this.value.id) {
                return 'Modifier';
            }

            return 'Ajouter';
        }

        get animalParams(): IAnimalFormParams {
            return {
                name: this.value.name,
                birth_date: this.value.birth_date,
                sex: this.value.sex,
                is_sterilized: this.value.is_sterilized ? this.value.is_sterilized : false,
                is_crossbreed: this.value.is_crossbreed ? this.value.is_crossbreed : false,
                species_id: this.value.species.id,
                breed_id: this.value.breed ? this.value.breed.id : null,
            };
        }

        public resetBookingprocess() {
            getModule(BookingModule, this.$store).resetBookingProcess();
        }

        public submitButtonClicked() {
            this.animalFormValid = true;

            if (this.value.id) {
                return this.submitUpdateAnimal();
            }

            return this.submitCreateAnimal();
        }

        public submitCreateAnimal() {
            const animalModule = getModule(AnimalModule, this.$store);
            const snackbarModule = getModule(SnackbarModule, this.$store);
            this.animalFormValid = true;

            if (this.loggedUser.account?.type !== 'veterinarian') {
                animalModule
                    .createAnimal({
                        client_id: this.loggedClient.id,
                        animal: this.animalParams,
                    })
                    .then(() => {
                        snackbarModule
                            .display({
                                message: `${this.value.name} a bien été créé(e) !`,
                                position: 'top',
                                color: 'success',
                            })
                        ;
                        this.animalFormValid = false;
                        this.closeForm();
                    })
                ;
            } else {
                if (!this.loggedUser.client) {
                    return;
                }

                if (!this.loggedUser.client.organization_id) {
                    return;
                }

                animalModule
                    .createAnimalByPro({
                        client_id: this.loggedUser.client?.id,
                        animal: this.animalParams,
                        organization_id: this.loggedUser.client.organization_id,
                    })
                    .then(() => {
                        snackbarModule
                            .display({
                                message: `${this.value.name} a bien été créé(e) !`,
                                position: 'top',
                                color: 'success',
                            })
                        ;

                        this.closeForm();
                    })
                ;
            }

        }

        public submitUpdateAnimal() {
            const animalModule = getModule(AnimalModule, this.$store);
            const snackbarModule = getModule(SnackbarModule, this.$store);

            animalModule
                .updateAnimal({
                    client_id: this.loggedClient.id,
                    animal_id: this.value.id,
                    animal: this.animalParams,
                })
                .then(() => {
                    snackbarModule
                        .display({
                            message: `${this.value.name} a bien été modifié(e) !`,
                            position: 'top',
                            color: 'success',
                        })
                    ;
                    this.animalFormValid = false;

                    this.closeForm();
                })
            ;
        }

        public closeForm() {
            this.$emit('close');
        }
    }
