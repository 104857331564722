
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {Prop} from 'vue-property-decorator';
    import {Route, NavigationGuardNext} from 'vue-router';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';

    import AppointmentCard from '@/components/AppointmentCard.vue';
    import {BookingModule} from '@/store/modules';

    import {IBooking, IClient} from '@/types';

    const accountNamespace = namespace('account');

    @Component<ViewAppointment>({
        components: {
            AppointmentCard,
        },
        beforeRouteEnter(to: Route, from: Route, next: any) {
            next((vm: ViewAppointment) => {
                getModule(BookingModule, vm.$store)
                    .fetchBooking({clientId: vm.client.id, bookingId: to.params.id})
                    .then((booking: IBooking) => vm.appointment = booking)
                ;
            });
        },
        beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
            document.documentElement.style.overflow = 'auto';
            next();
        },
    })
    export default class ViewAppointment extends Vue {
        @Prop() public sourceCard!: HTMLElement | null;

        public appointment: IBooking|null = null;
        public expanded: boolean = false;

        @accountNamespace.Getter('loggedClient')
        public client!: IClient;

        get cardState() {
            return {
                expanded: this.expanded,
                canGoBack: this.sourceCard !== null,
            };
        }

        get transitionName() {
            if (this.$vuetify.breakpoint.mdAndUp) {
                return 'card-slide-x';
            }

            return this.sourceCard ? 'card-to-detail' : 'card-slide-x';
        }

        get cardStyle() {
            if (this.transitionName === 'card-slide-x') {
                return {};
            }

            const position = (this.sourceCard as HTMLElement).getBoundingClientRect();

            return {
                width: `${position.width}px`,
                height: `${position.height}px`,
                transform: `translate3d(${position.left}px, ${position.top}px, 0)`,
            };
        }

        public cardEnter(element: HTMLElement) {
            document.documentElement.style.overflow = 'hidden';
        }


        public cardAfterEnter(element: HTMLElement) {
            this.expanded = true;
            this.$nextTick(() => element.classList.add('expanded'));
        }

        public cardBeforeLeave(element: HTMLElement) {
            this.$nextTick(() => {
                document.documentElement.style.overflowX = 'hidden';

                // Update element style. Vuejs doesn't react to getBoundingClientRect changes, so force it
                if (this.sourceCard && this.transitionName === 'card-to-detail') {
                    const position = (this.sourceCard as HTMLElement).getBoundingClientRect();

                    element.style.width = `${position.width}px`;
                    element.style.height = `${position.height}px`;
                    element.style.transform = `translate3d(${position.left}px, ${position.top}px, 0)`;
                }

                this.expanded = false;
                element.classList.remove('expanded');
            });
        }

        public cardAfterLeave() {
            document.documentElement.style.overflow = 'auto';
        }
    }
