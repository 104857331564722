
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {getModule} from 'vuex-module-decorators';
    import {namespace} from 'vuex-class';
    import AnimalFormCard from '@/components/AnimalFormCard.vue';
    import {AnimalModule} from '@/store/modules';
    import {
        IAnimal,
        IClient,
        IUser,
        AnimalUpdate,
    } from '@/types';
    import {
        bannerHelper,
    } from '@/helpers';

    const accountNamespace = namespace('account');
    const animalNamespace = namespace('animal');

    @Component({
        components: {
            AnimalFormCard,
        },
    })
    export default class ViewAnimals extends Vue {
        public deletingAnimal: IAnimal|null = null;
        public updatingAnimal: IAnimal|null = null;
        public deleteDialog: boolean = false;
        public createDialog: boolean = false;
        public openDialog: boolean = false;
        public loading = false;
        public dynaban: string = '';

        @accountNamespace.Getter('loggedClient')
        public client!: IClient;

        @animalNamespace.Getter('animalsList')
        public animals!: IAnimal[];

        @accountNamespace.Getter('loggedUser')
        public loggedUser!: IUser;

        public deleteAnimal(animal: IAnimal) {
            const animalModule = getModule(AnimalModule, this.$store);

            animalModule
                .deleteAnimal({client: this.client, animal})
                .then(() => {
                    this.deleteDialog = false;
                    this.deletingAnimal = null;
                })
            ;
        }

        public openDeleteAnimalDialog(animal: IAnimal) {
            this.deletingAnimal = animal;
            this.deleteDialog = true;
        }

        public openUpdateAnimalDialog(animal: IAnimal) {
            this.updatingAnimal = Object.assign({}, animal);
            this.createDialog = true;
        }

        public openCreateAnimalDialog(animal: IAnimal) {
            (this.updatingAnimal as AnimalUpdate) = {
                name: null,
                birth_date: null,
                species: null,
                breed: null,
                sex: null,
                is_sterilized: null,
            };
            this.createDialog = true;
        }

        public closeAnimalDialog() {
            this.createDialog = false;
            this.updatingAnimal = null;
        }

        private created() {
            this.dynaban = bannerHelper.getDynaBan();
            getModule(AnimalModule, this.$store).fetchAnimals(this.client.id);
        }
    }
